.admin-panel {
  padding: var(--app-padding);
}

.admin-panel-title {
  margin-bottom: 20px;
}

.button-container {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.search-bar-container {
  width: 100%;
  margin-bottom: 20px;
}

.table-container {
  width: 100%;
  margin-bottom: 20px;
}

.table-header {
  background-color: #f5f5f5;
}

.table-header th {
  padding: 10px;
}

.add-type-button {
  text-align: center;
  margin-top: 20px;
}
