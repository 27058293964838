.search-bar {
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .search-input {
    padding: 0.5rem;
    width: 100%;
    max-width: 400px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    transition: box-shadow 0.2s ease-in-out;
  }
  
  .search-input:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
    border-color: rgba(81, 203, 238, 1);
  }
  