.mockup-grid {
  display: grid;
  gap: 16px;
}

.mockup-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.variant-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.overlay-image {
  position: absolute;
}

.text-wrapper {
  margin-top: 8px;
}
