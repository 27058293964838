.alert-dialog .MuiDialogTitle-root {
    font-weight: bold;
  }
  
  .alert-dialog .MuiDialogContent-root {
    margin-top: 10px;
  }
  
  .alert-dialog .MuiDialogActions-root {
    justify-content: flex-end;
  }
  