/* General Layout */
.product-detail {
  margin: 20px;
  padding-bottom: 100px;
}

.back-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #007bff;
  margin-bottom: 20px;
  font-size: 1rem;
  font-weight: bold;
}

.back-button span {
  margin-left: 8px;
}

.product-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.carousel-container {
  max-width: 800px;
  margin: 20px auto;
}

.product-carousel-image {
  height: 400px;
  object-fit: contain;
}

.carousel-item {
  display: flex;
  justify-content: center;
}

.carousel-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-meta p {
  font-size: 0.9rem;
  color: #333;
  margin-bottom: 5px;
}

/* Meta Section */
.product-meta p {
  font-size: 0.9rem;
  color: #333;
  margin-bottom: 5px;
}


/* Margin at Bottom */
.bottom-margin {
  height: 100px;
}

/* Pricing Configuration Section */
.pricing-configurations {
  margin: 20px 0;
}

.config-title {
  margin-bottom: 20px;
  font-weight: bold;
}

.config-inputs {
  display: flex;
  align-items: center;
  gap: 10px;
}

.config-inputs .MuiTextField-root {
  flex: 1;
}

.config-inputs button {
  height: 40px;
}

.blocking-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader-content {
  background-color: white;
  padding: 40px 60px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.snackbar-progress {
  position: relative;
  width: 100%;
  height: 4px;
  background-color: rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.snackbar-progress-bar {
  position: absolute;
  height: 100%;
  background-color: #4caf50; /* Green for success */
  animation: progress 5s linear forwards; /* Match autoHideDuration */
}

@keyframes progress {
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
}
