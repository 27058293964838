.home-card {
  width: 100%;
  height: 300px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;
  text-align: center;
}

.home-card:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}

.card-content {
  text-align: center;
  padding: 20px;
}

.card-icon {
  font-size: 200px;
  margin-bottom: 16px;
}

.card-title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 16px;
}

.card-description {
  font-size: 18px;
  margin-bottom: 16px;
}
