.home-card {
  width: 100%;
  height: 500px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2) !important;
  transition: box-shadow 0.3s ease-in-out !important;
  cursor: pointer;
  text-align: center;
}

.home-card:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}

.card-content {
  text-align: center;
  padding: 30px !important;
}

.card-icon {
  font-size: 80px !important;
  margin-bottom: 26px !important;
}

.card-title {
  font-size: 32px !important;
  font-weight: bold !important;
  margin-bottom: 26px !important;
}

.card-description {
  font-size: 18px !important;
  margin-bottom: 26px !important;
}
