.color-variants-selector {
  margin-top: 20px;
}

.color-variant-label {
  display: flex;
  align-items: center;
}

.color-box {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}

.top-level-options {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}
