.color-variant-list {
  width: 100%;
  margin-top: 20px;
}

.color-variant-list img {
  width: 50px;
  height: 50px;
}

.button-group {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.dialog-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
