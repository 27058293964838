.size-variant-dialog {
    padding: var(--app-padding);
  }
  
  .size-variant-dialog-title {
    margin-bottom: 20px;
  }
  
  .size-variant-dialog form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  