.primary-variant-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.primary-variant-dropdown {
  flex: 1;
  min-width: 200px;
  display: flex;
  flex-direction: column;
}

.primary-variant-image {
  position: relative;
  margin-top: 10px;
  width: 400px;
  height: auto;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.primary-variant-image img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
  position: relative;
}

.overlay-box {
  position: absolute;
  border: 2px solid red;
  pointer-events: none;
}

.overlay-box-coordinates {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
}

.overlay-box-coordinates .MuiTextField-root {
  flex: 1;
}
