.home-card {
    cursor: pointer;
    transition: box-shadow 0.3s ease-in-out;
  }
  
  .home-card:hover {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  }
  
  .card-content {
    text-align: center;
  }
  
  .card-icon {
    font-size: 4rem;
    margin-bottom: 1rem;
  }
  
  .card-title {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .card-description {
    margin-bottom: 1rem;
  }
  